const routes = [
  {
    path: '/dashboard',
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'operation',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/dashboard/OperationPage.vue'
          ),
      },
    ],
  },
];

export default routes;
