import axios from 'axios';
import { setAuthInterceptors, setInterceptors } from './common/interceptors'; // fhapi server

function createInstance(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_MAS_API}${url}`,
  });
  return setInterceptors(instance);
}

function createAuthInstance(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_MAS_API}${url}`,
  });
  return setAuthInterceptors(instance);
}

export const login = createInstance('/login');
export const loginWithAuth = createAuthInstance('/login');
export const auth = createAuthInstance('/');
export const common = createAuthInstance('/common');
export const service = createAuthInstance('/service');
export const support = createAuthInstance('/support');
export const operation = createAuthInstance('/operation');
export const dashboard = createAuthInstance('/dashboard');
export const phr = createAuthInstance('/phr');
export const market = createAuthInstance('/market');
