<template>
  <div>
    <Dialog
      v-for="item in items"
      :item="item"
      @close="onClose"
      :key="item.key"
    />
  </div>
</template>

<script>
const key = () => `${Date.now()}-${Math.random()}`;
import Dialog from './Dialog.vue';

export default {
  name: 'Dialogs',
  components: { Dialog },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    open(message, { type, cancelLabel, size, okLabel = 'OK' }) {
      if (!this.$parent) {
        this.$mount();
        document.body.appendChild(this.$el);
      }
      return new Promise(resolve => {
        const item = {
          key: key(),
          show: true,
          message,
          type,
          cancelLabel,
          okLabel,
          size,
          resolve,
          popAni: true,
        };
        this.items.push(item);
      });
    },
    /**
     * 일반 알림 팝업
     */
    alert(message, options = {}) {
      const { type = 'default', okLabel = 'OK', size } = options;
      return this.open(message, { type, okLabel, size });
    },
    /**
     * confirm 팝업
     */
    confirm(message, options = {}) {
      const {
        type = 'default',
        cancelLabel = 'Cancel',
        okLabel = 'OK',
        size,
      } = options;
      return this.open(message, { type, cancelLabel, okLabel, size });
    },
    /**
     * 패스워드 팝업
     */
    password(message, options = {}) {
      const {
        type = 'password',
        cancelLabel = 'Cancel',
        okLabel = 'OK',
        size,
      } = options;
      return this.open(message, { type, cancelLabel, okLabel, size });
    },
    /**
     * 검색내용 편집 팝업
     */
    editParam(message, options = {}) {
      const {
        type = 'editParam',
        cancelLabel = 'Cancel',
        okLabel = 'OK',
        size,
      } = options;
      return this.open(message, { type, cancelLabel, okLabel, size });
    },
    /**
     * IP 추가 팝업
     */
    ipInsert(message, options = {}) {
      const {
        type = 'ipInsert',
        cancelLabel = 'Cancel',
        okLabel = 'OK',
        size,
      } = options;
      return this.open(message, { type, cancelLabel, okLabel, size });
    },
    /**
     * 팝업 닫기
     */
    onClose(item, ok) {
      const response = { ok };
      item.resolve(response); // 확인 버튼 클릭 시, callback
      item.show = false;
      this.remove(item);
    },
    remove(item) {
      let i = this.items.indexOf(item);
      if (i >= 0) {
        this.items.splice(i, 1);
      }
    },
    keyUp(e) {
      if ('Escape' === e.key) {
        if (this.items.length > 0) {
          this.onClose(this.items[this.items.length - 1]);
        }
      }
      if ('Enter' === e.key) {
        if (this.items.length > 0) {
          const item = this.items[this.items.length - 1];
          if (item.type == 'default') {
            this.onClose(this.items[this.items.length - 1], true);
          }
        }
      }
    },
  },
  created() {
    window.addEventListener('keyup', this.keyUp);
  },
  updated() {
    // dialog 2개 이상인 경우, 마지막 dialog만 pop-ani 적용
    if (this.items.length > 1) {
      this.items.forEach((item, index) => {
        if (index == this.items.length - 1) {
          item.popAni = true;
        } else {
          item.popAni = false;
        }
      });
    }
  },
};
</script>
