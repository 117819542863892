<template>
  <!-- 컴포넌트 적용할 view 페이지에 validation-observer 컴포넌트 사용할 것 -->
  <!-- 정규표현식 에러 메세지는 errorData.regex에 정의, 그 외 검증은 vee-validate.js에서 정의된 message 전달, 기본 message 커스텀이 필요하면 아래 label span 태그 failedRules 속성 및 errorData 추가  -->
  <validation-provider
    v-slot="{ errors, invalid, validated, failedRules }"
    :name="label"
    :rules="rules"
  >
    <textarea
      v-if="type === 'textarea'"
      :type="type"
      :value="value"
      :class="invalid && validated ? `${inputClass} error-tt` : `${inputClass}`"
      :style="inputStyle"
      :maxlength="maxlength"
      :dataType="dataType"
      :name="name"
      :placeholder="placeholder"
      :rows="rows"
      :cols="cols"
      :readonly="readonly"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-else
      :type="type"
      :value="value"
      :class="invalid && validated ? `${inputClass} error-tt` : `${inputClass}`"
      :style="inputStyle"
      :maxlength="maxlength"
      :dataType="dataType"
      :name="name"
      :readonly="readonly"
      :placeholder="placeholder"
      :disabled="disabled"
      :checked="checked"
      @click="$emit('click')"
      @input="$emit('input', $event.target.value)"
      @keydown="$emit('keydown', $event)"
      :autocomplete="dataType === 'PASSWORD' ? 'new-password' : 'off'"
    />
    <label
      v-if="invalid && validated"
      :class="invalid && validated ? 'error-tt' : ''"
      :for="name"
    >
      <span v-if="failedRules.regex">{{ errorData.regex }}</span>
      <span v-else-if="failedRules.required">{{
        (errorData && errorData.required) || errors[0]
      }}</span>
      <span v-else-if="failedRules.max">{{
        (errorData && errorData.max) || errors[0]
      }}</span>
      <span v-else>{{ errors[0] }}</span>
    </label>
  </validation-provider>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
export default {
  mixins: [CommonMixins],
  props: {
    label: { type: String },
    rules: { type: [String, Object] },
    type: {
      type: String,
      default: 'text',
    },
    inputClass: { type: String },
    inputStyle: { type: String },
    maxlength: { type: String },
    name: { type: String },
    dataType: {
      type: String,
      // default: 'LITERAL',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorData: { type: Object },
    placeholder: {
      type: String,
    },
    rows: {
      type: String,
    },
    cols: {
      type: String,
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
