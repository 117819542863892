<template>
  <div>
    <!-- alert/confirm 팝업 -->
    <div class="popup" v-if="item.show && item.type == 'default'">
      <div class="popup-mask"></div>
      <div
        :class="item.popAni ? 'pop-content pop-ani' : 'pop-content'"
        style="width:350px;"
        draggable="true"
      >
        <div class="pop-head">
          <h2>{{ defaultTitle }}</h2>
          <button class="btn-x" @click="onClose(false)"></button>
        </div>
        <div class="pop-body">
          <div class="pop-question" v-html="item.message"></div>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="ok"
              class="bt-line30 cr-orange"
              @click="onOk"
            />
            <!-- confirm Popup의 경우 -->
            <input
              v-if="item.cancelLabel"
              type="button"
              :value="cancel"
              class="bt-line30"
              @click="onClose(false)"
            />
          </p>
        </div>
      </div>
    </div>

    <!-- 비밀번호 확인 팝업 -->
    <div class="popup" v-if="item.show && item.type == 'password'">
      <div class="popup-mask"></div>
      <div
        :class="item.popAni ? 'pop-content pop-ani' : 'pop-content'"
        style="width:400px;"
      >
        <div class="pop-head">
          <h2>{{ pwTitle }}</h2>
          <button class="btn-x" @click="onClose(false)"></button>
        </div>
        <div class="pop-body">
          {{ pwDesc }}
          <table class="table-style-default table-pop mt-10">
            <colgroup>
              <col width="45%" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>{{ pwLabel }}<i class="star">*</i></th>
                <td @keyup.enter="keyupEnterPwd">
                  <validation-observer ref="sampleValidate">
                    <validate-form
                      :label="pwLabel"
                      type="password"
                      dataType="text"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                        min: 9,
                        max: 30,
                      }"
                      :errorData="{
                        ...errorData,
                        regex: pwInputErr,
                      }"
                      inputClass="input-24 w-100"
                      v-model="password"
                      name="password"
                    />
                  </validation-observer>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="ok"
              class="bt-line30 cr-orange"
              @click="onPwChk"
            />
            <input
              type="button"
              :value="cancel"
              class="bt-line30"
              @click="onClose(false)"
            />
          </p>
        </div>
      </div>
    </div>
    <!-- 검색 내용 편집 팝업 -->
    <div class="popup" v-if="item.show && item.type == 'editParam'">
      <div class="popup-mask"></div>
      <div
        :class="item.popAni ? 'pop-content pop-ani' : 'pop-content'"
        style="width:425px;"
      >
        <div class="pop-head">
          <h2>{{ editParamTitle }}</h2>
          <button class="btn-x" @click="onClose(false)"></button>
        </div>
        <div class="pop-body">
          <div class="mb-15">
            <h4 class="tx-total">· {{ editParamItem }}</h4>
            <div>
              <table
                class="table-style-default"
                id="popup_optsGrid"
                style="max-height:200px; overflow: auto;"
              >
                <colgroup>
                  <col width="150px" />
                  <col width="186.5px" />
                  <col width="38.5px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ editParamHeader1 }}</th>
                    <th>{{ editParamHeader2 }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody id="pop_opts_tbody">
                  <tr
                    v-for="index in optsIdx"
                    :key="index"
                    :id="`tr_opts_${index}`"
                  >
                    <td>
                      <input
                        autocomplete="off"
                        type="text"
                        :list="`select_opts_${index}_input`"
                        :id="`select_opts_${index}_input_load`"
                        :class="`sort-input-select pop-select`"
                        style="width:135.5px"
                        valuetemp=""
                        @focus="
                          InputDropList_Focus(`select_opts_${index}_input_load`)
                        "
                        @blur="
                          InputDropList_FocusOut(
                            `select_opts_${index}_input_load`,
                          )
                        "
                        @change="
                          InputDropList_OnChange(
                            `select_opts_${index}_input_load`,
                          )
                        "
                      />
                      <datalist :id="`select_opts_${index}_input`">
                        <option value="" selected hidden></option>
                        <option
                          v-for="item in searchResources[
                            item.message.fhirResrcType
                          ]"
                          :key="item.key"
                          :value="item.key"
                          >{{ item.key }}</option
                        >
                      </datalist>
                    </td>
                    <td>
                      <input
                        type="text"
                        :id="`input_opts_${index}`"
                        class="input-24 w-100 pop-input"
                        @blur="setPreviewFhirCont"
                        autocomplete="off"
                      />
                    </td>
                    <td>
                      <input
                        type="button"
                        class="bt-line22 bt-num-plus btnAddRow"
                        :class="
                          index == optsIdx ? 'bt-num-plus' : 'bt-num-minus'
                        "
                        :id="`opts_${index}`"
                        :value="index == optsIdx ? '+' : '-'"
                        @click="appendRow('opts', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-15">
            <h4 class="tx-total">· {{ editParamSort }}</h4>
            <div>
              <table
                class="table-style-default"
                id="popup_sortsGrid"
                style="max-height:200px; overflow: auto;"
              >
                <colgroup>
                  <col width="150px" />
                  <col width="186.5px" />
                  <col width="38.5px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ editParamHeader1 }}</th>
                    <th>{{ editParamHeader2 }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody id="pop_sorts_tbody">
                  <tr
                    v-for="index in sortsIdx"
                    :key="index"
                    :id="`tr_sorts_${index}`"
                  >
                    <td>
                      <input
                        type="text"
                        :list="`select_sorts_${index}_input`"
                        :id="`select_sorts_${index}_input_load`"
                        :class="`sort-input-select pop-select`"
                        style="width:135.5px"
                        valuetemp=""
                        @focus="
                          InputDropList_Focus(
                            `select_sorts_${index}_input_load`,
                          )
                        "
                        @blur="
                          InputDropList_FocusOut(
                            `select_sorts_${index}_input_load`,
                          )
                        "
                        @change="
                          InputDropList_OnChange(
                            `select_sorts_${index}_input_load`,
                          )
                        "
                      />
                      <datalist :id="`select_sorts_${index}_input`">
                        <option value="" selected disabled hidden></option>
                        <option
                          v-for="item in searchResultParams"
                          :key="item.key"
                          :value="item.key"
                          >{{ item.key }}</option
                        >
                      </datalist>
                    </td>
                    <td>
                      <input
                        type="text"
                        :id="`input_sorts_${index}`"
                        class="input-24 w-100 pop-input"
                        @blur="setPreviewFhirCont"
                        autocomplete="off"
                      />
                    </td>
                    <td>
                      <input
                        type="button"
                        class="bt-line22 bt-num-plus btnAddRow"
                        :class="
                          index == sortsIdx ? 'bt-num-plus' : 'bt-num-minus'
                        "
                        :id="`sorts_${index}`"
                        :value="index == sortsIdx ? '+' : '-'"
                        @click="appendRow('sorts', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-15">
            <h4 class="tx-total">· {{ editParamPreview }}</h4>
            <textarea
              class="input-24 w-100"
              id="popup_search_content"
              disabled="disabled"
              v-model="previewFhirCont"
              style="color: #5a5a5a; resize: none;"
            ></textarea>
          </div>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="ok"
              class="bt-line30 cr-orange"
              @click="onEditParam"
            />
            <input
              type="button"
              :value="cancel"
              class="bt-line30"
              @click="onClose(false)"
            />
          </p>
        </div>
      </div>
    </div>

    <div class="popup" v-if="item.show && item.type == 'ipInsert'">
      <div class="popup-mask"></div>
      <div
        :class="item.popAni ? 'pop-content pop-ani' : 'pop-content'"
        style="width:400px;"
      >
        <div class="pop-head">
          <h2>{{ ipTitle }}</h2>
          <button class="btn-x" @click="onClose(false)"></button>
        </div>
        <div class="pop-body">
          {{ ipDesc }}
          <table class="table-style-default table-pop mt-10">
            <colgroup>
              <col width="35%" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>IP</th>
                <td>
                  <input
                    label="IP"
                    type="text"
                    dataType="text"
                    :rules="{
                      required: true,
                    }"
                    class="input-24 w-100"
                    v-model="ip"
                    name="ip"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="ok"
              class="bt-line30 cr-orange"
              @click="onIpOk"
            />
            <input
              type="button"
              :value="cancel"
              class="bt-line30"
              @click="onClose(false)"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { checkPassword } from '@/api/operationApi';
import {
  SEARCH_RESOURCES,
  SEARCH_RESULT_PARAMETERS,
} from '@/views/phr/resourceSearch.js';
import i18n from '@/i18n/index';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  components: { ValidateForm },
  mixins: [CommonMixins],
  props: {
    item: {
      type: Object, // {show(Boolean), type(default: 알림팝업, password: 비밀번호 팝업)}
      required: true,
    },
  },
  data() {
    return {
      ip: '',
      password: '',
      errorData: {},
      optsIdx: 1,
      sortsIdx: 1,
      searchResources: SEARCH_RESOURCES,
      searchResultParams: SEARCH_RESULT_PARAMETERS,
      previewFhirCont: this.$store.getters['phr/getFhirCont'],
      fixFhirCont: this.$store.getters['phr/getFhirCont'].split('&')[0],
      type: this.$store.getters['phr/getFhirType'],
    };
  },
  async mounted() {
    // 초기값이 있을 경우 세팅
    // if (this.previewFhirCont.indexOf('&') > -1) {
    if (this.previewFhirCont.length > 0 && this.type == 'resource') {
      let params = this.previewFhirCont.split('&');
      let event = { target: { value: '+' } };
      let type, idx;
      params[0] = params[0].slice(1);
      for (let i = 0; i < params.length; i++) {
        if (params[i].indexOf('_') > -1) {
          type = 'sorts';
          await this.appendRow(type, event);
        } else {
          type = 'opts';
          await this.appendRow(type, event);
        }
        idx = type === 'opts' ? this.optsIdx - 1 : this.sortsIdx - 1;
        document.getElementById(
          `select_${type}_${idx}_input_load`,
        ).value = params[i].split('=')[0];
        document.getElementById(`input_${type}_${idx}`).value = params[i].split(
          '=',
        )[1];
      }
      this.setPreviewFhirCont();
    } else if (this.type == 'fhir') {
      let params = this.previewFhirCont.split('&');
      if (params.length > 1) {
        params[0] = params[0].slice(1);
        let event = { target: { value: '+' } };
        let type, idx;
        for (let i = 1; i < params.length; i++) {
          if (params[i].indexOf('_') > -1) {
            type = 'sorts';
            await this.appendRow(type, event);
          } else {
            type = 'opts';
            await this.appendRow(type, event);
          }
          idx = type === 'opts' ? this.optsIdx - 1 : this.sortsIdx - 1;
          document.getElementById(
            `select_${type}_${idx}_input_load`,
          ).value = params[i].split('=')[0];
          document.getElementById(`input_${type}_${idx}`).value = params[
            i
          ].split('=')[1];
        }
      }
      this.setPreviewFhirCont();
    }
  },
  methods: {
    /**
     * 팝업 닫기
     * response : {Boolean}
     * - true: 확인
     * - false: 취소/닫기
     */
    onClose(response) {
      this.$emit('close', this.item, response);
    },
    /**
     * ip 확인
     */
    onIpOk() {
      this.$store.commit('operator/setIp', this.ip);
      this.onClose(true);
      this.ip = '';
    },
    /**
     * 확인
     */
    onOk() {
      this.onClose(true);
    },
    /**
     * 비밀번호 확인
     */
    async onPwChk() {
      if (this.password == '') {
        const field = this.getPostWord(this.pwLabel, '을', '를');
        this.$dialogs.alert(
          i18n.t('common.alert.required-field', {
            field,
          }),
        );
        return;
      }

      await checkPassword({ password: this.password }).then(({ data }) => {
        const { valid } = data;
        if (valid) {
          this.password = '';
          this.onClose(true);
        } else {
          this.$dialogs.alert(i18n.t('common.alert.invalid-pw'));
          this.password = '';
        }
      });
    },
    keyupEnterPwd() {
      if (document.getElementsByClassName('popup').length == 1) {
        this.onPwChk();
      }
    },
    onEditParam() {
      this.$store.dispatch('phr/updateFhirCont', this.previewFhirCont);
      this.onClose(true);
    },
    appendRow(type, event) {
      const {
        target: { value, id },
      } = event;
      if (value === '+') {
        type === 'opts' ? this.optsIdx++ : this.sortsIdx++;
      } else {
        document.getElementById(`tr_${id}`).remove();
      }
      this.setPreviewFhirCont();
    },
    setPreviewFhirCont() {
      let optsRows = document.getElementById('pop_opts_tbody')?.children;
      let content = '';
      content += this.appendPreview(optsRows);
      let sortsRows = document.getElementById('pop_sorts_tbody')?.children;
      content += this.appendPreview(sortsRows);
      if (this.type == 'resource') {
        content = '?' + content.slice(1);
        content == '?' ? (content = '') : content;
      } else if (
        this.type == 'fhir' &&
        this.previewFhirCont.split('&').length > 1
      ) {
        content = '&' + content.slice(1);
        content == '?' ? (content = '') : content;
        content == '&' ? (content = '') : content;
      }
      // type 에 따라
      this.previewFhirCont =
        this.type === 'fhir'
          ? this.fixFhirCont + content
          : this.type === 'resource'
          ? content
          : '';
    },
    appendPreview(rows) {
      let content = '';
      for (let i in rows) {
        if (rows[i].cells) {
          let cells_select = rows[i].cells[0].children[0].value;
          let cells_input = rows[i].cells[1].children[0].value;

          if (cells_select && cells_input) {
            content += '&' + cells_select + '=' + cells_input;
          }
        }
      }
      return content;
    },
    InputDropList_Focus(elementID) {
      //input must have value and valuetemp attributes
      const el = document.getElementById(elementID);
      el.setAttribute('valuetemp', el.value);
      el.setAttribute('placeholder', el.value);
      el.value = '';
    },
    InputDropList_FocusOut(elementID) {
      const el = document.getElementById(elementID);
      if (el.value !== el.getAttribute('valuetemp'))
        el.value = el.getAttribute('valuetemp');
      this.setPreviewFhirCont();
    },
    InputDropList_OnChange(elementID) {
      const el = document.getElementById(elementID);
      el.setAttribute('valuetemp', el.value);
      el.blur();
    },
  },
  computed: {
    defaultTitle() {
      return i18n.t('common.popUp.title-default');
    },
    pwTitle() {
      return i18n.t('common.popUp.title-password');
    },
    pwDesc() {
      return i18n.t('common.popUp.desc-password');
    },
    pwLabel() {
      return i18n.t('common.popUp.password-label');
    },
    pwInputErr() {
      return i18n.t('common.validate.password-format');
    },
    editParamTitle() {
      return i18n.t('common.popUp.title-editParam');
    },
    editParamItem() {
      return i18n.t('common.popUp.editParam-label1');
    },
    editParamSort() {
      return i18n.t('common.popUp.editParam-label2');
    },
    editParamPreview() {
      return i18n.t('common.popUp.editParam-label3');
    },
    editParamHeader1() {
      return i18n.t('common.popUp.editParam-header1');
    },
    editParamHeader2() {
      return i18n.t('common.popUp.editParam-header2');
    },
    ipTitle() {
      return i18n.t('common.popUp.title-ipInsert');
    },
    ipDesc() {
      return i18n.t('common.popUp.desc-ipInsert');
    },
    ok() {
      return i18n.t('common.btn.ok');
    },
    cancel() {
      return i18n.t('common.btn.cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-calendar-picker-indicator {
  opacity: 0;
}
input.pop-select {
  padding-right: 20px !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAG2YAABzjgAA4DIAAIM2AAB5CAAAxgwAADT6AAAgbL5TJ5gAAABGSURBVHjaYvz//z8DOYCJgUzA0tnZidPK8vJyRpw24pLEpwnuVHRFhDQxMDAwMPz//x+OOzo6/iPz8WFGuocqAAAA//8DAD/sORHYg7kaAAAAAElFTkSuQmCC)
    right center no-repeat;
}
</style>
