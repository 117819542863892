/**
 * 다른 모듈의 state를 참조하기 위해서는
 * actions와 getters에서 rootState를 활용해준다.
 * mutations에서 활용해야 하는 경우, actions에서 내려줘야 함.
 * https://vuex.vuejs.org/kr/guide/modules.html
 */
import { getMenuList, sessionCheck, logout } from '@/api/loginApi';

export default {
  namespaced: true,
  state: {
    isLogined: sessionStorage.getItem('isLogined') || false,
    userName: sessionStorage.getItem('userName') || null,
    userId: sessionStorage.getItem('userId') || null,
    token: sessionStorage.getItem('token') || null,
    menuList: [],
  },
  // 다른 모듈의 state 사용시 context.rootState
  actions: {
    async FETCH_MENU_LIST(context) {
      const response = await getMenuList();
      context.commit('SET_MENU', response.data);
      return response;
    },
    async FETCH_SESSION(context) {
      const response = await sessionCheck();
      context.commit('SET_SESSION', response.data);
      return response;
    },
    async LOGOUT(context) {
      const response = await logout();
      context.commit('CLEAR_SESSION');
      return response;
    },
  },
  mutations: {
    SET_LOGIN(state, loginData) {
      state.isLogined = true;
      state.userName = loginData.userName;
      state.userId = loginData.userId;
      state.token = loginData.token;
      sessionStorage.setItem('isLogined', true);
      sessionStorage.setItem('userName', loginData.userName);
      sessionStorage.setItem('userId', loginData.userId);
      sessionStorage.setItem('token', loginData.token);
    },
    SET_MENU(state, menuList) {
      let parseMenuList = [
        ...menuList.map(item => ({
          ...item,
          linkPath:
            '/' +
            item.linkPath.split('/')[2] +
            '/' +
            item.linkPath.split('/')[3],
          subMenuList: [
            ...item.subMenuList.map(subItem => ({
              ...subItem,
              linkPath:
                '/' +
                subItem.linkPath.split('/')[2] +
                '/' +
                subItem.linkPath.split('/')[3],
            })),
          ],
        })),
      ];

      // onm & onmaz 로 시작하지 않을 경우, sample Tab 추가
      if (!window.location.href.startsWith('https://onm')) {
        parseMenuList.push({
          menuNo: 7000,
          menuLevel: '1',
          menuName: '샘플',
          upMenuNo: 0,
          linkPath: '/sample/sample',
          imagePath: '',
          menuOrder: 7,
          subMenuList: [
            {
              imagePath: '',
              linkPath: '/sample/sample',
              menuLevel: '2',
              menuName: '샘플',
              menuNo: 7100,
              menuOrder: 1,
              subMenuList: null,
              upMenuNo: 7000,
            },
            {
              imagePath: '',
              linkPath: '/sample/panel',
              menuLevel: '2',
              menuName: '패널',
              menuNo: 7200,
              menuOrder: 2,
              subMenuList: null,
              upMenuNo: 7000,
            },
          ],
        });
      }

      state.menuList = parseMenuList;
      sessionStorage.setItem('menuList', parseMenuList);
    },
    SET_SESSION(state, data) {
      if (state.token != data.token) {
        console.log('세션 절반 이상 지난 경우 토큰 재발급 확인');
      }
      if (data.valid) {
        state.token = data.token;
      }
    },
    CLEAR_SESSION(state) {
      state.isLogined = false;
      state.userName = null;
      state.userId = null;
      state.token = null;
      state.menuList = [];
      sessionStorage.removeItem('isLogined');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('token');
    },
  },
  getters: {
    getLoginStatus: state => state.isLogined,
  },
};
