import store from '@/store/index';

const routes = [
  {
    path: '/phr',
    component: () =>
      import(/* webpackChunkName: "phr" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'resource',
        component: () =>
          import(/* webpackChunkName: "phr" */ '@/views/phr/ResourcePage.vue'),
      },
      {
        path: 'fhir',
        component: () =>
          import(/* webpackChunkName: "phr" */ '@/views/phr/FhirPage.vue'),
      },
      {
        path: 'annymty',
        component: () =>
          import(/* webpackChunkName: "phr" */ '@/views/phr/AnnymtyPage.vue'),
        beforeEnter: async (to, from, next) => {
          // 서비스 목록
          await store.dispatch('common/FETCH_SRVC_LIST').then(async () => {
            // 비식별화 결과 초기화 (사용자)
            await store
              .dispatch(
                'phr/FETCH_CLEAR_PROGRESS_RESULT',
                store.state.phr.annymty.PROP_USER_ANNYMTY,
              )
              .then(async () => {
                // 비식별화 결과 초기화 (측정내역)
                await store
                  .dispatch(
                    'phr/FETCH_CLEAR_PROGRESS_RESULT',
                    store.state.phr.annymty.PROP_USER_MESURE,
                  )
                  .then(async () => {
                    // 비식별화 Thread 존재 여부 조회
                    await store
                      .dispatch('phr/FETCH_PROGRESS_ANNYMTY')
                      .then(() => {
                        next();
                      });
                  });
              });
          });
        },
      },
    ],
  },
];

export default routes;
