import { getSrvcList, getMarketCommonCode } from '@/api/commonApi';
import {
  MARKET_COMMON_GROUP_CODE,
  MARKET_COMMON_BANNER_TYPE_CODE,
} from '../properties';

export default {
  namespaced: true,
  state: {
    goodsCnTypeCode: [],
    srvcList: [], // 서비스 목록
    bannerTypeCode: [],
  },
  actions: {
    // 마켓 공통코드 가져오기 : 컨텐츠 유형 코드
    async FETCH_GOOD_CN_TYPE_CODE(context) {
      const response = await getMarketCommonCode(
        MARKET_COMMON_GROUP_CODE.GOODS_CN_TYPE_CODE,
      );
      context.commit('SET_GOOD_CN_TYPE_CODE', response.data);
      return response;
    },
    // 서비스 목록 가져오기
    async FETCH_SRVC_LIST(context) {
      const response = await getSrvcList();
      context.commit('SET_SRVC_LIST', response.data);
      return response;
    },

    // 마켓 배너 타입 목록 가져오기 :
    async FETCH_BANNER_TYPE_CODE(context) {
      const response = await getMarketCommonCode(
        MARKET_COMMON_BANNER_TYPE_CODE,
      );
      context.commit('SET_BANNER_TYPE_CODE', response.data);
      return response;
    },
  },
  getters: {
    getBnrType: state => state.bannerTypeCode,
  },
  mutations: {
    SET_GOOD_CN_TYPE_CODE(state, data) {
      state.goodsCnTypeCode = data;
    },
    SET_BANNER_TYPE_CODE(state, data) {
      state.bannerTypeCode = data;
    },
    SET_SRVC_LIST(state, data) {
      const srvcList = data.map(val => {
        return {
          srvcId: val.srvcId,
          srvcNm: val.srvcNm,
        };
      });
      state.srvcList = srvcList;
    },
  },
};
