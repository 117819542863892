/**
 * https://kr.vuejs.org/v2/guide/mixins.html
 */
/* eslint-disable */
import i18n from '@/i18n/index';

export default {
  mounted() {
    window.scrollTo(0, 0);
    this.$nextTick(function() {
      // startsWith
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }

      // endsWith
      if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(suffix) {
          return this.indexOf(suffix, this.length - suffix.length) !== -1;
        };
      }
    });
  },
  methods: {
    // input에 특수문자 입력 방지
    inputLiteralCheck(e) {
      const regex = /[~!@\#$%^&*\=+']/gi;
      if (regex.test(e.key)) {
        e.preventDefault();
      }
    },
    // input에 숫자와 백스페이스만 입력
    inputNumberCheck(e) {
      const keyCode = e.keyCode || e.which;
      const regex = /[^0-9]/g;
      if (regex.test(e.key) && keyCode !== 8) {
        e.preventDefault();
      }
    },
    // input에 숫자, 소수점, 백스페이스만 입력
    inputFloatCheck(e) {
      const keyCode = e.keyCode || e.which;
      const regex = /[^0-9]/g;
      if (regex.test(e.key) && keyCode !== 8 && e.key !== '.') {
        e.preventDefault();
      }
    },
    /**
     * date format (formatDate(nowDate, 'yyyy-MM-dd') 와 같이 사용)
     * @param {*} date
     * @param {*} dateFormat
     * @returns
     */
    formatDate(date, dateFormat) {
      String.prototype.string = function(len) {
        var s = '',
          i = 0;
        while (i++ < len) {
          s += this;
        }
        return s;
      };
      String.prototype.zf = function(len) {
        return '0'.string(len - this.length) + this;
      };
      Number.prototype.zf = function(len) {
        return this.toString().zf(len);
      };
      return dateFormat.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function(
        $1,
      ) {
        switch ($1) {
          case 'yyyy':
            return date.getFullYear();
          case 'yy':
            return (date.getFullYear() % 1000).zf(2);
          case 'MM':
            return (date.getMonth() + 1).zf(2);
          case 'dd':
            return date.getDate().zf(2);
          case 'HH':
            return date.getHours().zf(2);
          case 'hh':
            return (date.getHours() % 12 ? date.getHours() % 12 : 12).zf(2);
          case 'mm':
            return date.getMinutes().zf(2);
          case 'ss':
            return date.getSeconds().zf(2);
          default:
            return $1;
        }
      });
    },
    // string escape
    jsonEscape(string) {
      let result = string.replace('\\n', '<br/>');
      result = string.replace('\n', '<br/>');
      return result;
    },
    // 단어 종성 받침 체크 (getPostWord(str, '을', '를') 와 같이 사용)
    getPostWord(str, firstVal, secondVal) {

      // locale이 영어일 경우 체크하지 않고 리턴
      if(i18n.locale === 'en') return str;

      try {
        let lastStr = str[str.length - 1].charCodeAt(0);
        // 한글의 제일 처음과 끝의 범위밖일 경우는 오류
        if (lastStr < 0xac00 || lastStr > 0xd7a3) {
          return str;
        }
        let lastCharIndex = (lastStr - 0xac00) % 28;
        // 종성인덱스가 0이상일 경우는 받침이 있는경우이며 그렇지 않은경우는 받침이 없는 경우
        if (lastCharIndex > 0) {
          // 받침이 있는경우
          // 조사가 '로'인경우 'ㄹ'받침으로 끝나는 경우는 '로' 나머지 경우는 '으로'
          if (firstVal === '으로' && lastCharIndex === 8) str += secondVal;
          else str += firstVal;
        } else {
          // 받침이 없는 경우
          str += secondVal;
        }
      } catch (e) {
        console.error(e);
      }
      return str;
    },
    /**
     * 1631684473000 시 2021/09/15 14:41:13으로 반환
     * @param {*} longValue
     * @returns
     */
    getFormatDate(longValue) {
      if (!longValue) {
        return '';
      }
      const dateObj = new Date(longValue);
      return this.formatDate(dateObj, 'yyyy/MM/dd HH:mm:ss');
    },
    /**
     * 01000000000 ==> 010-0000-0000으로 반환
     * @param {*} phoneNo
     * @returns
     */
    getPhoneNoText(phoneNo) {
      if (!phoneNo) {
        return '';
      }

      const phoneDelimiter = '-';
      let result;
      if (phoneNo && phoneNo.length == 8) {
        result =
        phoneNo.substring(0, 4) + phoneDelimiter + phoneNo.substring(4, 9);
      } else if (phoneNo && phoneNo.length == 9 && phoneNo.startsWith('02')) {
        result =
        phoneNo.substring(0, 2) +
        phoneDelimiter +
        phoneNo.substring(2, 5) +
        phoneDelimiter +
        phoneNo.substring(5, 9);
      } else if (phoneNo && phoneNo.length == 10 && phoneNo.startsWith('02')) {
        result =
        phoneNo.substring(0, 2) +
        phoneDelimiter +
        phoneNo.substring(2, 6) +
        phoneDelimiter +
        phoneNo.substring(6, 10);
      } else if (
        phoneNo &&
        phoneNo.length == 10 &&
        !phoneNo.startsWith('010')
        ) {
        result =
          phoneNo.substring(0, 3) +
          phoneDelimiter +
          phoneNo.substring(3, 6) +
          phoneDelimiter +
          phoneNo.substring(6, 10);
      } else if (
        phoneNo &&
        phoneNo.length == 11 &&
        !phoneNo.startsWith('010')
      ) {
        result =
          phoneNo.substring(0, 3) +
          phoneDelimiter +
          phoneNo.substring(3, 7) +
          phoneDelimiter +
          phoneNo.substring(7, 11);
      } else if (phoneNo && phoneNo.length == 11) {
        result =
          phoneNo.substring(0, 3) +
          phoneDelimiter +
          phoneNo.substring(3, 7) +
          phoneDelimiter +
          phoneNo.substring(7, 11);
      } else {
        result = phoneNo;
      }

      if (result) {
        return result;
      } else {
        return '';
      }
    },
    /**
     * 010-0000-0000 ==> ['010','0000','0000']으로 반환
     * @param {*} phoneNo
     * @returns
     */
     getPhoneTextArr(phoneNo) {
      if (!phoneNo) {
        return '';
      }

      let result = [];
     
      if (phoneNo && phoneNo.length == 8) {
        result = [phoneNo.substring(0, 4),phoneNo.substring(4, 9)];
      } else if (phoneNo && phoneNo.length == 9 && phoneNo.startsWith('02')) {
        result = [phoneNo.substring(0, 2),phoneNo.substring(2, 5).phoneNo.substring(5, 9)];
      } else if (phoneNo && phoneNo.length == 10 && phoneNo.startsWith('02')) {
        result =[phoneNo.substring(0, 2),phoneNo.substring(2, 6),phoneNo.substring(6, 10)];
      } else if (
        phoneNo &&
        phoneNo.length == 10 &&
        !phoneNo.startsWith('010')
      ) {
        result = [phoneNo.substring(0, 3),phoneNo.substring(3, 6),phoneNo.substring(6, 10)];
      } else if (
        phoneNo &&
        phoneNo.length == 11 &&
        !phoneNo.startsWith('010')
      ) {
        result = [phoneNo.substring(0, 3),phoneNo.substring(3, 7),phoneNo.substring(7, 11)];
      } else if (phoneNo && phoneNo.length == 11) {
        result = [phoneNo.substring(0, 3),phoneNo.substring(3, 7),phoneNo.substring(7, 11)];
      } else {
        result = phoneNo;
      }
      if (result) {
        return result;
      } else {
        return '';
      }
    },
    /**
     * 빈 Object 체크
     * @param {*} param
     * @returns
     */
    isEmptyObject(param) {
      return Object.keys(param).length === 0 && param.constructor === Object;
    },
    /**
     * Pretty Print JSON
     * @param {*} str 
     * @returns 
     */
    getJSONPrettyPrint(str) {
      try {
        return JSON.stringify(this.getFormatJson(str), 2, 2);
      } catch (e) {
        return str;
      }
    },
    getFormatJson(jsonVal) {
      if (
        jsonVal == '' ||
        jsonVal == null ||
        jsonVal == undefined ||
        jsonVal == 'undefined'
      ) {
        return '';
      } else {
        return JSON.parse(jsonVal);
      }
    },
  },
};
