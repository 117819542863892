import { market } from './index';
import store from '@/store/index';
import axios from 'axios';

// ----------------- 입점현황 ----------------- //

/**
 * 입점 현황 조회
 * @returns
 */
function getStoreStatus() {
  return market.post('/store/status');
}

/**
 * 입점 목록 조회
 * @param {*} payload
 * @returns
 */
function getStoreList(payload) {
  return market.post('/store/list', payload);
}

/**
 * 업체 상세 정보 조회
 * @param {*} oprtrId
 * @returns
 */
function getStoreDetail(oprtrId) {
  return market.post('/store/info', { oprtrId });
}

// ----------------- 컨텐츠 등록 현황 ----------------- //

/**
 * 상위 카테고리 조회
 * @returns
 */
function getUpperGoodsCtgryCode() {
  return market.get('/goods/ctgryCode');
}

/**
 * 하위 카테고리 조회
 * @param {*} upperGoodsCtgryCode
 * @returns
 */
function getGoodsCtgryCode(upperGoodsCtgryCode) {
  return market.get(`/goods/ctgryCode/${upperGoodsCtgryCode}`);
}

/**
 * 컨텐츠 등록현황 조회
 * @returns
 */
function getGoodsStatus() {
  return market.get('/goods/status');
}

/**
 * 컨텐츠 개수 조회 by 상위카테고리
 * @param {*} upperGoodsCtgryCode
 */
function getCtgryCnt(upperGoodsCtgryCode) {
  return market.get(`/goods/ctgryStatus/${upperGoodsCtgryCode}`);
}

/**
 * 상품 목록 조회
 * @param {*} payload
 * @returns
 */
function getGoodsList(payload) {
  return market.post('/goods/list', payload);
}

/**
 * 카테고리 목록 조회
 * @returns
 */
function getCtgryList() {
  return market.get('/goods/ctgryCode/list');
}

/**
 * 상품 존재 여부 확인
 * @param {*} payload
 * @returns
 */
function chkCtgry(payload) {
  return market.post('/goods/chkCtgry', payload);
}

/**
 * 카테고리 업데이트
 * @param {*} payload
 * @returns
 */
function updateCtgry(payload) {
  return market.post('/goods/update', payload);
}

// ----------------- 입점승인 ----------------- //

/**
 * 월별 입점 현황 조회
 * @param {*} statusPageNo
 */
function getMonthApproveStatus(statusPageNo) {
  return market.get(`/store/storeApproveStatus/${statusPageNo}`);
}

/**
 * 입점 승인 상태 업데이트
 * @param {*} payload
 */
function processApproveStatus(payload) {
  return market.post('/store/processApproveStatus', payload);
}

// ----------------- 배너 관리 ----------------- //

/**
 * 마켓 배너 조회
 * @param {*} payload
 */
function getBannerlist(payload) {
  return market.post('banner/list', payload);
}

/**
 * 마켓 배너 상세 조회
 * @param {string} bannerSn
 */
function getDetailBanner(bannerSn) {
  return market.post('banner/detail', bannerSn);
}

/**
 * 마켓 배너 등록
 * @param {*} payload
 */
function insertBanner(payload) {
  return market.post('banner', payload);
}

/**
 * 마켓 배너 삭제
 * @param {*} payload
 */
async function deleteBanner(payload) {
  return market.delete('banner', { data: payload });
}

/**
 * 마켓 배너 수정
 * @param {*} payload
 */
function updateBanner(payload) {
  return market.put('banner', payload);
}

/**
 * 마켓 배너 이미지 상세 조회
 * @param {string} bannerImageSn
 */
function detailBannerImg(bannerImageSn) {
  return market.post('banner/image/info', bannerImageSn);
}

/**
 * 마켓 배너 이미지 수정
 * @param {string} bannerImageSn
 * @param {*} payload
 */
async function updateBannerImg(bannerImageSn, payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/market/banner/image/${bannerImageSn}`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}

/**
 * 마켓 배너 이미지 등록
 * @param {string} bannerImageSn
 * @param {*} payload
 */
async function insertBannerImg(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/market/banner/image`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}

/**
 * 마켓 배너 이미지 삭제
 * @param {*} payload
 */
async function deleteBannerImg(payload) {
  return market.delete('banner/image', { data: payload });
}

export {
  getStoreStatus,
  getStoreList,
  getStoreDetail,
  getUpperGoodsCtgryCode,
  getGoodsCtgryCode,
  getGoodsStatus,
  getCtgryCnt,
  getGoodsList,
  getCtgryList,
  chkCtgry,
  updateCtgry,
  getMonthApproveStatus,
  processApproveStatus,
  getBannerlist,
  getDetailBanner,
  insertBanner,
  deleteBanner,
  updateBanner,
  detailBannerImg,
  updateBannerImg,
  insertBannerImg,
  deleteBannerImg,
};
