import { dashboard } from './index';

/**
 * 기본 차트 호출
 * - 서비스별 패킷 전송건수
 * - PHR 정보 수집현황
 * - 서비스별 가입자수
 * @returns
 */
function getChartsList() {
  return dashboard.get('/operation');
}

/**
 * 플랫폰 이용현황
 * 실시간 차트 데이터 조회
 * @returns
 */
async function setLineCharData() {
  return dashboard.get('/operation/real');
}

export { getChartsList, setLineCharData };
