import {
  checkProgressThread,
  getThread,
  getCsvFile,
  anonymizeUser,
  anonymizeMesure,
  stopThread,
} from '@/api/phrApi';
import CommonMixins from '@/mixins/CommonMixins';
import { ANNYMTY_THREAD_RESULT } from '@/store/properties';

export default {
  namespaced: true,
  state: {
    fhirCont: '',
    type: '',
    // 비식별화
    annymty: {
      // 비식별화 properties
      PROP_USER_ANNYMTY: 'USER_ANNYMTY',
      PROP_USER_MESURE: 'USER_MESURE',
      /**
       * 비식별화 진행 여부
       * progress: 진행 여부
       * filePath : 이전에 진행한 비식별화의 filePath
       *            (filePath 존재 여부로 이전에 진행한 비식별화 결과를 확인 했는지 알 수 있음.)
       *            끝났음에도 결과를 확인하지 못했을 경우, filePath가 존재
       * result: 이전에 진행한 비식별화 결과
       * csvInterval : csvReadInterval
       * stopFlag: Thread 중단중..
       */
      USER_ANNYMTY: {
        progress: false,
        filePath: null,
        threadResult: '',
        arxResult: '',
        csvReadList: [],
        csvInterval: null,
        stopFlag: false,
      },
      USER_MESURE: {
        progress: false,
        filePath: null,
        threadResult: '',
        arxResult: '',
        csvReadList: [],
        csvInterval: null,
        stopFlag: false,
      },
    },
  },
  actions: {
    updateFhirCont({ commit }, value) {
      commit('setFhirCont', value);
    },
    updateFhirType({ commit }, value) {
      commit('setFhirType', value);
    },
    /**
     * 비식별화 진행 여부 조회
     * @param {*} context
     * @returns
     */
    async FETCH_PROGRESS_ANNYMTY(context) {
      const response = await checkProgressThread();
      context.commit('SET_PROGRESS_ANNYMTY', response.data);
      return response;
    },
    /**
     * Thread 조회
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     * @returns
     */
    async FETCH_THREAD(context, type) {
      context.state.annymty[type].threadResult = ANNYMTY_THREAD_RESULT.PROGRESS;
      // `이전에 진행하신 비식별화가 진행중입니다. (${context.state.annymty[type].filePath})`;
      const response = await getThread(type);
      context.commit('SET_THREAD', { type, data: response.data });
      return response;
    },
    /**
     * 비식별화
     * @param {*} context
     * @param {*} param1 type: 유형 (사용자/측정내역) reqObj: reqObj
     * @returns
     */
    async FETCH_ANONIMIZE(context, { type, reqObj }) {
      let response;
      if (type == context.state.annymty.PROP_USER_ANNYMTY) {
        response = await anonymizeUser(reqObj);
      } else {
        response = await anonymizeMesure(reqObj);
      }
      context.commit('SET_ANONIMIZE', { type, data: response.data });
      return response;
    },
    /**
     * Thread 중단
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     * @returns
     */
    async STOP_THREAD(context, type) {
      const filePath = context.state.annymty[type].filePath;
      let response;
      context.commit('SET_STOP_FLAG', { type, stopFlag: true });
      context.state.annymty[type].stopFlag = true;
      await stopThread(type, filePath).then(res => {
        response = res;
        context.commit('SET_STOP_THREAD', { type, data: res.data });
      });
      context.commit('SET_STOP_FLAG', { type, stopFlag: false });
      return response;
    },
    /**
     * filePath 설정
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     * @returns
     */
    FETCH_PROGRESS_FILEPATH(context, type) {
      context.commit('SET_PROGRESS_FILEPATH', type);
      return context.state.annymty[type].filePath;
    },
    /**
     * CsvReadInterval 시작
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     * @returns
     */
    async FETCH_CSV_LIST(context, type) {
      let response;
      const filePath = context.state.annymty[type].filePath;
      context.state.annymty[type].csvInterval = setInterval(async function() {
        await getCsvFile(filePath).then(({ data }) => {
          response = context.commit('SET_CSV_LIST', { type, data });
        });
      }, 5000);
      return response;
    },
    /**
     * 기존 결과 삭제 (result만)
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     */
    FETCH_CLEAR_PROGRESS_RESULT(context, type) {
      context.commit('SET_CLEAR_PROGRESS_RESULT', type);
    },
    /**
     * 진행 여부 삭제 (fileNm/progress)
     * 화면 진입 혹은 새로운 Thread 시작 시, 삭제
     * @param {*} context
     * @param {*} type 유형 (사용자/측정내역)
     */
    FETCH_CLEAR_PROGRESS_FILEPATH(context, type) {
      context.commit('SET_CLEAR_PROGRESS_FILEPATH', type);
    },
  },
  mutations: {
    setFhirCont(state, data) {
      state.fhirCont = data;
    },
    setFhirType(state, data) {
      state.type = data;
    },
    SET_PROGRESS_ANNYMTY(state, data) {
      const {
        userAnnymtyThread,
        userMesureThread,
        prevUserAnnymtyRslt,
        prevUserMesureRslt,
      } = data;

      // 진행 여부 (boolean)
      state.annymty.USER_ANNYMTY.progress = userAnnymtyThread;
      state.annymty.USER_MESURE.progress = userMesureThread;

      // progress: false
      // 이전에 진행한 비식별화 결과를 확인하지 못하였을 경우, 이전 결과값을 넣어준다. (filePath로 체크..)
      if (!userAnnymtyThread && state.annymty.USER_ANNYMTY.filePath) {
        state.annymty.USER_ANNYMTY.threadResult = ANNYMTY_THREAD_RESULT.FINISH;
        // '이전에 진행하신 비식별화가 완료되었습니다.';
        console.log(state.annymty.USER_ANNYMTY.threadResult);
        state.annymty.USER_ANNYMTY.arxResult =
          prevUserAnnymtyRslt.arxResult || '';
        state.annymty.USER_ANNYMTY.csvReadList =
          prevUserAnnymtyRslt.csvReadList || [];
        // 이전 filePath null처리
        state.annymty.USER_ANNYMTY.filePath = null;
      }

      if (!userMesureThread && state.annymty.USER_MESURE.filePath) {
        state.annymty.USER_MESURE.threadResult = ANNYMTY_THREAD_RESULT.FINISH;
        // '이전에 진행하신 비식별화가 완료되었습니다.';
        state.annymty.USER_MESURE.arxResult =
          prevUserMesureRslt.arxResult || '';
        state.annymty.USER_MESURE.csvReadList =
          prevUserMesureRslt.csvReadList || [];
        // 이전 filePath null처리
        state.annymty.USER_MESURE.filePath = null;
      }
    },
    SET_THREAD(state, { type, data }) {
      const { csvReadList, arxResult } = data;
      state.annymty[type].arxResult = arxResult;
      state.annymty[type].csvReadList = csvReadList;
      state.annymty[type].threadResult = ANNYMTY_THREAD_RESULT.FINISH;
      // '이전에 진행하신 비식별화가 완료되었습니다';
    },
    SET_ANONIMIZE(state, { type, data }) {
      state.annymty[type].arxResult = data.arxResult;
      state.annymty[type].csvReadList = data.csvReadList;
    },
    SET_STOP_THREAD(state, { type, data }) {
      // 성공시 resultClear & interval 중단
      if (data.valid) {
        clearInterval(state.annymty[type].csvInterval);
        state.annymty[type].csvInterval = null;
        state.annymty[type].threadResult = '';
        state.annymty[type].arxResult = '';
        state.annymty[type].csvReadList = [];
      }
    },
    SET_PROGRESS_FILEPATH(state, type) {
      // 진행중..
      state.annymty[type].progress = true;
      // filePath가 null인 경우, 새로운 Thread 시작 (filePath 생성)
      if (!state.annymty[type].filePath) {
        const folderNm = CommonMixins.methods.formatDate(
          new Date(),
          'yyyyMMdd',
        );
        const fileNm = `${type}_${CommonMixins.methods.formatDate(
          new Date(),
          'yyyyMMddHHmmss',
        )}`;
        state.annymty[type].filePath = folderNm + '/' + fileNm;
      }
      // else: filePath가 state존재하는 경우, Thread 존재.. 기존 state유지
    },
    SET_CSV_LIST(state, { type, data }) {
      state.annymty[type].csvReadList = data;
    },
    SET_CLEAR_PROGRESS_RESULT(state, type) {
      // 비식별화 결과 및 interval 초기화 (filePath는 완료 여부를 위해 지우지 않는다.)
      clearInterval(state.annymty[type].csvInterval);
      state.annymty[type].csvInterval = null;
      state.annymty[type].threadResult = '';
      state.annymty[type].arxResult = '';
      state.annymty[type].csvReadList = [];
    },
    SET_CLEAR_PROGRESS_FILEPATH(state, type) {
      if (!state.annymty[type].stopFlag) {
        // 비식별화 중단중이 아닌 경우에만... (중단중인 경우, 중단 완료 후, clear처리)
        // 비식별화/Thread 완료 후, 진행여부 관련 state 삭제 시켜줌
        clearInterval(state.annymty[type].csvInterval);
        state.annymty[type].csvInterval = null;
        state.annymty[type].progress = false;
        //  비식별화 페이지에서 완료된 것이면, filePath null처리 후 결과 바로 출력
        if (window.location.pathname.includes('annymty')) {
          state.annymty[type].filePath = null;
        }
      }
    },
    SET_STOP_FLAG(state, { type, stopFlag }) {
      // 비식별화 중단 Flag
      state.annymty[type].stopFlag = stopFlag;
    },
  },
  getters: {
    getFhirCont: state => state.fhirCont,
    getFhirType: state => state.type,

    // textArea 출력용
    getUserCsvString(state) {
      return state.annymty.USER_ANNYMTY.csvReadList
        ? state.annymty.USER_ANNYMTY.csvReadList.join('\n')
        : '';
    },
    getMesureCsvString(state) {
      return state.annymty.USER_MESURE.csvReadList
        ? state.annymty.USER_MESURE.csvReadList.join('\n')
        : '';
    },
  },
};
