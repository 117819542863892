const SEARCH_RESOURCES = {
  Account: [{ key: 'period', type: 'date' }],
  ActivityDefinition: [],
  AllergyIntolerance: [
    { key: 'asserter', type: 'reference' },
    { key: 'recorder', type: 'reference' },
    { key: 'date', type: 'date' },
  ],
  AdverseEvent: [{ key: 'date', type: 'date' }],
  Appointment: [
    { key: 'actor', type: 'reference' },
    { key: 'date', type: 'date' },
  ],
  AppointmentResponse: [{ key: 'actor', type: 'reference' }],
  AuditEvent: [{ key: 'date', type: 'date' }],
  Binary: [],
  BodySite: [{ key: 'code', type: 'token' }],
  CarePlan: [
    { key: 'date', type: 'date' },
    { key: 'context', type: 'reference' },
  ],
  CareTeam: [
    { key: 'date', type: 'date' },
    { key: 'context', type: 'reference' },
  ],
  ChargeItem: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
  ],
  Claim: [],
  ClaimResponse: [],
  ClinicalImpression: [
    { key: 'date', type: 'date' },
    { key: 'context', type: 'reference' },
  ],
  CodeSystem: [
    { key: 'code', type: 'token' },
    { key: 'content-mode', type: 'token' },
    { key: 'date', type: 'date' },
    { key: 'name', type: 'string' },
    { key: 'title', type: 'string' },
    { key: 'system', type: 'uri' },
    { key: 'url', type: 'uri' },
  ],
  Communication: [{ key: 'context', type: 'reference' }],
  CommunicationRequest: [{ key: 'context', type: 'reference' }],
  CompartmentDefinition: [],
  Composition: [{ key: 'date', type: 'date' }],
  ConceptMap: [],
  Condition: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
  ],
  Consent: [{ key: 'date', type: 'date' }],
  Contract: [],
  Coverage: [],
  DetectedIssue: [{ key: 'date', type: 'date' }],
  Device: [],
  DeviceComponent: [],
  DeviceMetric: [],
  DeviceRequest: [{ key: 'code', type: 'token' }],
  DeviceUseStatement: [],
  DiagnosticReport: [
    { key: 'code', type: 'token' },
    { key: 'encounter', type: 'reference' },
    { key: 'date', type: 'date' },
  ],
  DocumentManifest: [],
  DocumentReference: [{ key: 'encounter', type: 'reference' }],
  EligibilityRequest: [],
  EligibilityResponse: [],
  Encounter: [{ key: 'date', type: 'date' }],
  Endpoint: [],
  EnrollmentRequest: [],
  EnrollmentResponse: [],
  EpisodeOfCare: [{ key: 'date', type: 'date' }],
  ExpansionProfile: [],
  ExplanationOfBenefit: [{ key: 'encounter', type: 'reference' }],
  FamilyMemberHistory: [
    { key: 'code', type: 'token' },
    { key: 'date', type: 'date' },
    { key: 'gender', type: 'token' },
  ],
  Flag: [{ key: 'encounter', type: 'reference' }],
  Goal: [{ key: 'start-date', type: 'date' }],
  GraphDefinition: [],
  Group: [{ key: 'code', type: 'token' }],
  GuidanceResponse: [],
  HealthcareService: [],
  ImagingManifest: [],
  ImagingStudy: [{ key: 'context', type: 'reference' }],
  Immunization: [{ key: 'date', type: 'date' }],
  ImmunizationRecommendation: [{ key: 'date', type: 'date' }],
  ImplementationGuide: [],
  Library: [],
  Linkage: [],
  List: [
    { key: 'code', type: 'token' },
    { key: 'date', type: 'date' },
    { key: 'encounter', type: 'reference' },
  ],
  Location: [],
  Measure: [],
  MeasureReport: [],
  Media: [
    { key: 'date', type: 'date' },
    { key: 'context', type: 'reference' },
  ],
  Medication: [],
  MedicationAdministration: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
  ],
  MedicationDispense: [{ key: 'code', type: 'token' }],
  MedicationRequest: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
    { key: 'date', type: 'date' },
  ],
  MedicationStatement: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
  ],
  MessageDefinition: [],
  MessageHeader: [],
  NamingSystem: [],
  NutritionOrder: [{ key: 'datetime', type: 'date' }],
  Observation: [
    { key: 'code', type: 'token' },
    { key: 'component-code', type: 'token' },
    { key: 'combo-code', type: 'token' },
    { key: 'date', type: 'date' },
    { key: 'context', type: 'reference' },
  ],
  OperationDefinition: [],
  OperationOutcome: [],
  Organization: [],
  Parameters: [],
  Patient: [
    { key: 'gender', type: 'token' },
    { key: 'birthdate', type: 'date' },
    { key: 'phone', type: 'token' },
    { key: 'name', type: 'string' },
  ],
  PaymentNotice: [],
  PaymentReconciliation: [],
  Person: [
    { key: 'gender', type: 'token' },
    { key: 'birthdate', type: 'date' },
  ],
  PlanDefinition: [],
  Practitioner: [],
  PractitionerRole: [],
  Procedure: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
    { key: 'date', type: 'date' },
  ],
  ProcedureRequest: [
    { key: 'code', type: 'token' },
    { key: 'context', type: 'reference' },
  ],
  ProcessRequest: [],
  ProcessResponse: [],
  Provenance: [],
  Questionnaire: [],
  QuestionnaireResponse: [{ key: 'context', type: 'reference' }],
  ReferralRequest: [{ key: 'context', type: 'reference' }],
  RelatedPerson: [
    { key: 'gender', type: 'token' },
    { key: 'birthdate', type: 'date' },
  ],
  RequestGroup: [{ key: 'context', type: 'reference' }],
  ResearchStudy: [],
  ResearchSubject: [{ key: 'date', type: 'date' }],
  RiskAssessment: [{ key: 'date', type: 'date' }],
  Schedule: [{ key: 'date', type: 'date' }],
  SearchParameter: [],
  Sequence: [],
  ServiceDefinition: [],
  Specimen: [],
  Subscription: [],
  Substance: [],
  SupplyDelivery: [],
  SupplyRequest: [{ key: 'date', type: 'date' }],
  Task: [{ key: 'code', type: 'token' }],
  TestScript: [],
  TestReport: [],
  ValueSet: [
    { key: 'code', type: 'token' },
    { key: 'date', type: 'date' },
    { key: 'name', type: 'string' },
    { key: 'title', type: 'string' },
    { key: 'system', type: 'uri' },
    { key: 'url', type: 'uri' },
  ],
  VisionPrescription: [{ key: 'encounter', type: 'reference' }],
};
const SEARCH_RESULT_PARAMETERS = [
  { key: '_sort', type: 'string' },
  { key: '_count', type: 'number' },
  { key: '_summary', type: 'boolean' },
];

// FHIR 리소스 목록
const FHIR_RESOURCES = [
  // { value: '', text: '리소스 타입' },
  { value: 'Account', text: 'Account', patient: 'subject' },
  { value: 'ActivityDefinition', text: 'ActivityDefinition' },
  {
    value: 'AllergyIntolerance',
    text: 'AllergyIntolerance',
    patient: 'patient',
  },
  { value: 'AdverseEvent', text: 'AdverseEvent', patient: 'subject' },
  { value: 'Appointment', text: 'Appointment', patient: 'patient' },
  {
    value: 'AppointmentResponse',
    text: 'AppointmentResponse',
    patient: 'patient',
  },
  { value: 'AuditEvent', text: 'AuditEvent', patient: 'patient' },
  //{ value: "Basic", text: "Basic" },
  { value: 'Binary', text: 'Binary' },
  { value: 'BodySite', text: 'BodySite', patient: 'patient' },
  //{ value: "Bundle", text: "Bundle" },
  //{ value: "CapabilityStatement", text: "CapabilityStatement" },
  { value: 'CarePlan', text: 'CarePlan', patient: 'subject' },
  { value: 'CareTeam', text: 'CareTeam', patient: 'subject' },
  { value: 'ChargeItem', text: 'ChargeItem', patient: 'subject' },
  { value: 'Claim', text: 'Claim', patient: 'patient' },
  { value: 'ClaimResponse', text: 'ClaimResponse', patient: 'patient' },
  {
    value: 'ClinicalImpression',
    text: 'ClinicalImpression',
    patient: 'subject',
  },
  { value: 'CodeSystem', text: 'CodeSystem' },
  { value: 'Communication', text: 'Communication', patient: 'subject' },
  {
    value: 'CommunicationRequest',
    text: 'CommunicationRequest',
    patient: 'subject',
  },
  { value: 'CompartmentDefinition', text: 'CompartmentDefinition' },
  { value: 'Composition', text: 'Composition', patient: 'patient' },
  { value: 'ConceptMap', text: 'ConceptMap' },
  { value: 'Condition', text: 'Condition', patient: 'subject' },
  { value: 'Consent', text: 'Consent', patient: 'patient' },
  { value: 'Contract', text: 'Contract', patient: 'patient' },
  { value: 'Coverage', text: 'Coverage', patient: 'subscriber' },
  //{ value: "DataElement", text: "DataElement" },
  { value: 'DetectedIssue', text: 'DetectedIssue', patient: 'patient' },
  { value: 'Device', text: 'Device', patient: 'patient' },
  { value: 'DeviceComponent', text: 'DeviceComponent' },
  { value: 'DeviceMetric', text: 'DeviceMetric' },
  { value: 'DeviceRequest', text: 'DeviceRequest', patient: 'patient' },
  {
    value: 'DeviceUseStatement',
    text: 'DeviceUseStatement',
    patient: 'patient',
  },
  { value: 'DiagnosticReport', text: 'DiagnosticReport', patient: 'patient' },
  { value: 'DocumentManifest', text: 'DocumentManifest', patient: 'subject' },
  { value: 'DocumentReference', text: 'DocumentReference', patient: 'subject' },
  {
    value: 'EligibilityRequest',
    text: 'EligibilityRequest',
    patient: 'patient',
  },
  { value: 'EligibilityResponse', text: 'EligibilityResponse' },
  { value: 'Encounter', text: 'Encounter', patient: 'subject' },
  { value: 'Endpoint', text: 'Endpoint' },
  { value: 'EnrollmentRequest', text: 'EnrollmentRequest', patient: 'subject' },
  { value: 'EnrollmentResponse', text: 'EnrollmentResponse' },
  { value: 'EpisodeOfCare', text: 'EpisodeOfCare', patient: 'patient' },
  { value: 'ExpansionProfile', text: 'ExpansionProfile' },
  {
    value: 'ExplanationOfBenefit',
    text: 'ExplanationOfBenefit',
    patient: 'patient',
  },
  {
    value: 'FamilyMemberHistory',
    text: 'FamilyMemberHistory',
    patient: 'patient',
  },
  { value: 'Flag', text: 'Flag', patient: 'patient' },
  { value: 'Goal', text: 'Goal', patient: 'subject' },
  { value: 'GraphDefinition', text: 'GraphDefinition' },
  { value: 'Group', text: 'Group', patient: 'member' },
  { value: 'GuidanceResponse', text: 'GuidanceResponse', patient: 'subject' },
  { value: 'HealthcareService', text: 'HealthcareService' },
  { value: 'ImagingManifest', text: 'ImagingManifest', patient: 'patient' },
  { value: 'ImagingStudy', text: 'ImagingStudy', patient: 'patient' },
  { value: 'Immunization', text: 'Immunization', patient: 'patient' },
  {
    value: 'ImmunizationRecommendation',
    text: 'ImmunizationRecommendation',
    patient: 'patient',
  },
  { value: 'ImplementationGuide', text: 'ImplementationGuide' },
  { value: 'Library', text: 'Library' },
  { value: 'Linkage', text: 'Linkage' },
  { value: 'List', text: 'List', patient: 'subject' },
  { value: 'Location', text: 'Location' },
  { value: 'Measure', text: 'Measure' },
  { value: 'MeasureReport', text: 'MeasureReport', patient: 'patient' },
  { value: 'Media', text: 'Media', patient: 'subject' },
  { value: 'Medication', text: 'Medication' },
  {
    value: 'MedicationAdministration',
    text: 'MedicationAdministration',
    patient: 'patient',
  },
  {
    value: 'MedicationDispense',
    text: 'MedicationDispense',
    patient: 'patient',
  },
  { value: 'MedicationRequest', text: 'MedicationRequest', patient: 'patient' },
  {
    value: 'MedicationStatement',
    text: 'MedicationStatement',
    patient: 'patient',
  },
  { value: 'MessageDefinition', text: 'MessageDefinition' },
  { value: 'MessageHeader', text: 'MessageHeader' },
  { value: 'NamingSystem', text: 'NamingSystem' },
  { value: 'NutritionOrder', text: 'NutritionOrder', patient: 'patient' },
  { value: 'Observation', text: 'Observation', patient: 'subject' },
  { value: 'OperationDefinition', text: 'OperationDefinition' },
  { value: 'OperationOutcome', text: 'OperationOutcome' },
  { value: 'Organization', text: 'Organization' },
  { value: 'Parameters', text: 'Parameters' },
  { value: 'Patient', text: 'Patient' },
  { value: 'PaymentNotice', text: 'PaymentNotice' },
  { value: 'PaymentReconciliation', text: 'PaymentReconciliation' },
  { value: 'Person', text: 'Person', patient: 'patient' },
  { value: 'PlanDefinition', text: 'PlanDefinition' },
  { value: 'Practitioner', text: 'Practitioner' },
  { value: 'PractitionerRole', text: 'PractitionerRole' },
  { value: 'Procedure', text: 'Procedure', patient: 'patient' },
  { value: 'ProcedureRequest', text: 'ProcedureRequest', patient: 'patient' },
  { value: 'ProcessRequest', text: 'ProcessRequest' },
  { value: 'ProcessResponse', text: 'ProcessResponse' },
  { value: 'Provenance', text: 'Provenance', patient: 'patient' },
  { value: 'Questionnaire', text: 'Questionnaire' },
  {
    value: 'QuestionnaireResponse',
    text: 'QuestionnaireResponse',
    patient: 'patient',
  },
  { value: 'ReferralRequest', text: 'ReferralRequest', patient: 'patient' },
  { value: 'RelatedPerson', text: 'RelatedPerson', patient: 'patient' },
  { value: 'RequestGroup', text: 'RequestGroup', patient: 'patient' },
  { value: 'ResearchStudy', text: 'ResearchStudy' },
  { value: 'ResearchSubject', text: 'ResearchSubject', patient: 'patient' },
  { value: 'RiskAssessment', text: 'RiskAssessment', patient: 'patient' },
  { value: 'Schedule', text: 'Schedule', patient: 'actor' },
  { value: 'SearchParameter', text: 'SearchParameter' },
  { value: 'Sequence', text: 'Sequence', patient: 'patient' },
  { value: 'ServiceDefinition', text: 'ServiceDefinition' },
  //{ value: "Slot", text: "Slot" },
  { value: 'Specimen', text: 'Specimen', patient: 'patient' },
  //{ value: "StructureDefinition", text: "StructureDefinition" },
  //{ value: "StructureMap", text: "StructureMap" },
  { value: 'Subscription', text: 'Subscription' },
  { value: 'Substance', text: 'Substance' },
  { value: 'SupplyDelivery', text: 'SupplyDelivery', patient: 'patient' },
  { value: 'SupplyRequest', text: 'SupplyRequest', patient: 'requester' },
  { value: 'Task', text: 'Task', patient: 'patient' },
  { value: 'TestScript', text: 'TestScript' },
  { value: 'TestReport', text: 'TestReport' },
  { value: 'ValueSet', text: 'ValueSet' },
  {
    value: 'VisionPrescription',
    text: 'VisionPrescription',
    patient: 'patient',
  },
];

// Patient Reference를 가지는 리소스 목록
// var patientParamNames = [
//	   Account,
//	   AdverseEvent,
//	   AllergyIntolerance,
//	   Appointment,
//     AppointmentResponse,
//     AuditEvent,
//     Basic,
//     BodySite,
//     CarePlan,
//     CareTeam,
//     ChargeItem,
//     Claim,
//     ClaimResponse,
//     ClinicalImpression,
//     Communication,
//     CommunicationRequest,
//     Composition,
//     Condition,
//     Consent,
//     Contract,
//     Coverage,
//     DetectedIssue,
//     Device,
//     DeviceRequest,
//     DeviceUseStatement,
//     DiagnosticReport,
//     DocumentManifest,
//     DocumentReference,
//     EligibilityRequest,
//     Encounter,
//     EnrollmentRequest,
//     EpisodeOfCare,
//     ExplanationOfBenefit,
//     FamilyMemberHistory,
//     Flag,
//     Goal,
//     Group,
//     GuidanceResponse,
//     ImagingManifest,
//     ImagingStudy,
//     Immunization,
//     ImmunizationRecommendation,
//     List,
//     MeasureReport,
//     Media,
//     MedicationAdministration,
//     MedicationDispense,
//     MedicationRequest,
//     MedicationStatement,
//     NutritionOrder,
//     Observation,
//     Person,
//     Procedure,
//     ProcedureRequest,
//     Provenance,
//     QuestionnaireResponse,
//     ReferralRequest,
//     RelatedPerson,
//     RequestGroup,
//     ResearchSubject,
//     RiskAssessment,
//     Schedule,
//     Sequence,
//     Specimen,
//     SupplyDelivery,
//     SupplyRequest,
//     Task,
//     VisionPrescription
//]

export { FHIR_RESOURCES, SEARCH_RESOURCES, SEARCH_RESULT_PARAMETERS };
