/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import auth from './modules/auth';
import charts from './modules/charts';
import common from './modules/common';
import phr from './modules/phr';
import operator from './modules/operator';
import transactionlog from './modules/transactionlog'
import market from './modules/market';

Vue.use(Vuex);

// localStorage 사용
const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: state => ({  // 저장하고 싶은 state만 저장
    pathName: state.pathName, // header selected 용 (뒤로가기 시, selected 변하도록 state에 저장)
    auth: {
      menuList: state.auth.menuList,
      token: state.auth.token
    },
    phr: {
      annymty: state.phr.annymty
    }
  })
});

export default new Vuex.Store({
  state: {
    pathName: ''
  },
  actions: {
  },
  mutations: {
    SET_PATH_NAME(state, path) {
      state.pathName = `${path}`;
    }
  },
  getters: {},
  modules: {
    auth,
    charts,
    common,
    phr,
    operator,
    transactionlog,
    market
  },
  plugins: [vuexLocalStorage.plugin],
});
