const routes = [
  {
    path: '/sample',
    component: () =>
      import(/* webpackChunkName: "sample" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'sample',
        component: () =>
          import(
            /* webpackChunkName: "sample" */ '@/views/sample/SamplePage.vue'
          ),
      },
      {
        path: 'table-view',
        component: () =>
          import(
            /* webpackChunkName: "sample" */ '@/views/sample/SampleTablePage.vue'
          ),
      },
      {
        path: 'validate-form',
        component: () =>
          import(
            /* webpackChunkName: "sample" */ '@/views/sample/ValidateFormPage.vue'
          ),
      },
      {
        path: 'panel',
        component: () =>
          import(
            /* webpackChunkName: "sample" */ '@/views/sample/PanelPage.vue'
          ),
      },
    ],
  },
];

export default routes;
