const state = {
  trnscSns: '',
};
const getters = {
  getTrnscSns: state => state.trnscSns,
};
const mutations = {
  setTrnscSns(state, data) {
    state.trnscSns = data;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
