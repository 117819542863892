import { common, operation } from './index';

/**
 * 공통코드 조회 (phrp_r4)
 * @param {*} groupIds
 * @returns
 */
function getCommonCode(groupIds) {
  const isMultipleGroupCodes = typeof groupIds !== 'string';
  let comCodeUrl;

  if (isMultipleGroupCodes) {
    comCodeUrl = '?groupCodeIds=';

    groupIds.forEach(groupId => {
      comCodeUrl += groupId + ',';
    });

    comCodeUrl = comCodeUrl.substring(0, comCodeUrl.length - 1);
  } else {
    comCodeUrl = '/' + groupIds;
  }

  return common.get(`/code${comCodeUrl}`);
}

/**
 * 서비스 목록 조회
 * (operation 에서 가져오나 공통으로 쓰이기 때문에 commonAPI로 임시 등록)
 * store(common.js)에서 action 호출 시, state에 저장
 * @returns
 */
function getSrvcList() {
  return operation.get('/operator/srvcList');
}

/**
 * 마켓 공통 코드 조회
 * @param {*} groupId
 * @returns
 */
function getMarketCommonCode(groupId) {
  return common.get(`/market/code/${groupId}`);
}

/**
 * FHIR 코드 목록 조회
 * @returns
 */
function getFHIRCodes() {
  return common.get(`/fhir/code`);
}

export { getCommonCode, getSrvcList, getMarketCommonCode, getFHIRCodes };
